import "./scrollend_polyfill";
import "./carousel_button";
import "./carousel.scss";

class CarouselElement extends HTMLElement {
  static get observedAttributes() {
    return ["current-index", "autoplay"];
  }

  constructor() {
    super();
    this.addEventListener("slider:click", this);
    this.addEventListener("mouseenter", this);
    this.addEventListener("touchstart", this);
    this.setAria(0);
    const slider = this.querySelector(".slider");
    slider.addEventListener("scrollend", this);
  }

  connectedCallback() {
    const noHover = window.matchMedia("(hover: none)");
    if (noHover.matches) {
      this.setAttribute("autoplay", false);
    }
  }

  attributeChangedCallback(name, oldValue, newValue) {
    const lastIndex = this.totalSlides - 1;
    switch (name) {
      case "current-index":
        if (!newValue || newValue === oldValue) return;
        if (newValue >= lastIndex) {
          clearInterval(this.autoplay);
        }
        break;
      case "autoplay":
        if (!newValue || newValue === oldValue) return;
        if (newValue === "false") {
          clearInterval(this.autoplay);
        } else {
          this.startAutoplay();
        }
        break;
      default:
        break;
    }
  }

  handleEvent(event) {
    const slider = this.querySelector(".slider");
    const calculatedIndex = Math.round(
      (slider.scrollLeft / slider.scrollWidth) * this.totalSlides
    );
    let desiredSlide;
    switch (event.type) {
      case "scrollend":
        this.setAria(calculatedIndex);
        break;
      case "slider:click":
        desiredSlide = event.detail.slideIndex;
        this.slideTo(parseInt(desiredSlide, 10));
        this.setAria(parseInt(desiredSlide, 10));
        break;
      case "mouseenter":
      case "touchstart":
        // clearInterval(this.autoplay);
        this.setAttribute("autoplay", false);
        break;
      default:
    }
  }

  startAutoplay() {
    this.autoplay = setInterval(() => {
      const nextIndex = parseInt(this.getAttribute("current-index"), 10) + 1;
      this.slideTo(nextIndex);
      this.setAria(nextIndex);
    }, this.autoplayInterval);
  }

  slideTo(slideIndex) {
    // Necessary because Safari doesn't override smooth automatically like Chrome/Firefox
    const isReduced =
      window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
      window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;
    const scrollBehavior = isReduced ? "auto" : "smooth";
    const slider = this.querySelector(".slider");
    const leftValue = Math.floor(
      slider.scrollWidth * (slideIndex / this.totalSlides)
    );
    slider.scrollTo({ left: leftValue, behavior: scrollBehavior });
  }

  setAria(slideIndex) {
    this.setAttribute("current-index", slideIndex);
    const slideButtons = this.querySelectorAll("carousel-button");
    slideButtons.forEach((button, i) => {
      const buttonBoolean = i === slideIndex;
      button
        .querySelector("button")
        .setAttribute("aria-pressed", buttonBoolean);
    });
  }

  get currentSlide() {
    const activeButton = this.querySelector(
      `carousel-button button[aria-pressed='true']`
    );
    const activeIndex = parseInt(
      activeButton.closest("carousel-button").dataset.index,
      10
    );
    return activeIndex;
  }

  get totalSlides() {
    return parseInt(this.getAttribute("count"), 10);
  }

  get autoplayInterval() {
    return parseInt(this.getAttribute("autoplay-interval"), 10);
  }
}

customElements.define("carousel-element", CarouselElement);
