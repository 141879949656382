class CarouselButton extends HTMLElement {
  constructor() {
    super();
    this.addEventListener("click", this);
    this.setAria();
  }

  setAria() {
    const button = this.querySelector("button");
    const { label } = button.dataset;
    button.setAttribute("aria-label", label);
  }

  handleEvent(event) {
    if (event.type === "click") {
      const clicky = new CustomEvent("slider:click", {
        bubbles: true,
        detail: {
          slideIndex: this.slideIndex,
        },
      });
      this.dispatchEvent(clicky);
    }
  }

  get slideIndex() {
    return this.dataset.index;
  }
}
customElements.define("carousel-button", CarouselButton);
